<template>
	<div class="coupon">
		<div class="headers">
			<div v-for="(item,index) in list" :key='index' :class="filg==index?'actvlist':''" class="helist" @click="switeh(index)">{{item.name}}</div>
		</div>
		<div class="concent">
			<div v-for="(item,index) in lista.records" :key='index' class="coup" :class="item.memberCouponStatus=='NEW'?'':'coup_actv'">
				<div class="coup_left">
					<div class="coup_lefta">
						<div :class="item.memberCouponStatus!=='NEW'?'actv_lefta':''">
							<span>￥</span>
							<span style="font-size: 24px;">{{item.price}}</span>
						</div>
						<div :class="item.memberCouponStatus!=='NEW'?'actv_leftas':''">满{{item.consumeThreshold}}元可用</div>
					</div>
					<div style="margin-top: 5px;">使用范围：{{item.scopeType!='PORTION_GOODS'?'平台全部商品可用':'平台部分商品可用'}}</div>
					<div style="margin-top: 5px;color: #818181;">有效期：{{item.endTime}}</div>
				</div>
				<div class="coup_img">
					<img v-if="item.memberCouponStatus=='EXPIRE'" src="../../../assets/img/xie/yiguoqi.png" />
					<img v-if="item.memberCouponStatus=='USED'" src="../../../assets/img/xie/yishiyong.png" />
				</div>
				<div class="coup_right" @click="routers(item)">立即使用</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getcoupon } from "@/request/xie";
	export default{
		data(){
			return{
				list:[
					{name:'全部'},
					{name:'未使用'},
					{name:'已使用'},
					{name:'已过期'}
				],
				filg:0,
				lista:[],
				parsm:{
					pageNumber:1,
					pageSize:10,
					memberCouponStatus:''
				}
			}
		},
		mounted() {
			this.getlist()
		},
		methods:{
			//获取我的优惠券
			async getlist(){
				let res=await getcoupon(this.parsm)
				this.lista=res.result
				//console.log(res)
			},
			//切换
			switeh(ind){
				this.filg=ind
				if(this.filg==0){
					this.parsm.memberCouponStatus=''
				}else if(this.filg==1){
					this.parsm.memberCouponStatus='NEW'
				}else if(this.filg==2){
					this.parsm.memberCouponStatus='USED'
				}
				else if(this.filg==3){
					this.parsm.memberCouponStatus='EXPIRE'
				}
				this.parsm.pageNumber=1
				this.getlist()
			},
			//立即使用
			routers(item){
				if(item.memberCouponStatus=='NEW'){
					this.$router.push('../OpusMall')
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.coupon{
		.headers{
			height: 63px;
			display: flex;
			background-color: #fff;
			padding: 0 50px 0 40px;
			line-height: 60px;
			.helist{
				color: #818181;
				font-size: 16px;
				margin-right: 20px;
				cursor: pointer;
			}
			.actvlist{
				color: #00A3E0;
				border-bottom: 2px solid #00A3E0;
			}
		}
		.concent{
			margin-top: 8px;
			padding: 28px 46px;
			box-sizing: border-box;
			background-color: #fff;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			.coup{
				width: 382px;
				height: 124px;
				display: flex;
				background-image: url('../../../assets/img/xie/blue.png');
				background-size: 100% 100%;
				flex-flow: row;
				justify-content: space-between;
				margin-right: 28px;
				margin-bottom: 22px;
				.coup_left{
					font-size: 14px;
					color: #535353;
					padding-left: 27px;
					padding-top: 20px;
					box-sizing: border-box;
					.coup_lefta{
						display: flex;
						align-items: center;
						div:first-child{
							color: #00A3E0;
							margin-right: 7px;
						}
						div:last-child{
							padding: 3px 9px;
							box-sizing: border-box;
							background-color: #E3F7FF;
							color: #0075AB;
							font-size: 12px;
						}
					}
				}
				.coup_img{
					display: flex;
					flex-flow: column;
					justify-content: center;
				}
				.coup_right{
					cursor: pointer;
					width: 52px;
					color: #fff;
					font-size: 16px;
					display: flex;
					flex-flow: column;
					justify-content: center;
					align-items: center;
					writing-mode: tb-rl;
					letter-spacing:-2px
				}
			}
			.coup_actv{
				background-image: url('../../../assets/img/xie/hui.png');
				.actv_lefta{
					color: #999999 !important;
				}
				.actv_leftas{
					background-color: #E5E5E5 !important;
					color: #999999 !important;
				}
			}
		}
	}
</style>
